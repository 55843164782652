import React from "react";

import IntroBlock from "../IntroBlock/";

import refinanceImg from "./../../images/typesImg/Icons_Refinance_small.png";
import homeLoanImg from "./../../images/typesImg/Icons_homeloans_active_small.png";
import secondLoanImg from "./../../images/typesImg/Icons_SecondHome_small.png";
import reverseImg from "./../../images/typesImg/Icons_ReverseMortgage_small_Active copy.png";

import styles from "./styles.module.less";
import GetStartedCinemaTop from "../cinemagraphs/GetStartedCinemaTop";

const CurrentHOPanel = props => {
    const heroBG = "#F6F6F6";

    const header = (
        <span>
            You’ve done it once. <br className={styles.mobileHide} />
            Let us help you do it again.
        </span>
    );
    const par =
        "As a current homeowner, there are a lot of things you need to know when it comes to your current house and the loan that goes with it. Have no fear. We’re here to help you navigate the unknown.";

    return (
        <div
            style={{
                padding: 0,
                margin: 0,
                backgroundColor: heroBG,
                textAlign: "center",
            }}>
            <GetStartedCinemaTop />

            <IntroBlock header={header} par={par} />
            <section className={styles.CurrentHOPanel}>
                <div className={styles.subcontainer}>
                    <a href="#refinance">
                        <div className={styles.product}>
                            <div className={styles.icon}>
                                <img
                                    src={refinanceImg}
                                    alt="Refinance Your Mortgage - Refinance Icon | Bay Equity Home Loans"
                                />
                            </div>
                            <h1 className={styles.caption}>
                                Refinance
                                <br />
                            </h1>
                        </div>
                    </a>
                    <a href="#homeloan">
                        <div className={styles.product}>
                            <div className={styles.icon}>
                                <img
                                    src={homeLoanImg}
                                    alt="Get a Home Loan - Home Loan Icon | Bay Equity Home Loans"
                                />
                            </div>

                            <h1 className={styles.caption}>Home Loan</h1>
                        </div>
                    </a>

                    <a href="#secondloan">
                        <div className={styles.product}>
                            <div className={styles.icon}>
                                <img
                                    src={secondLoanImg}
                                    alt="Get a Second-Home Loan - Second-Home Loan Icon | Bay Equity Home Loans"
                                />
                            </div>
                            <h1 className={styles.caption}>Second-Home Loan</h1>
                        </div>
                    </a>
                    <a href="#reverse">
                        <div className={styles.product}>
                            <div className={styles.icon}>
                                <img
                                    src={reverseImg}
                                    alt="Get a Reverse Mortgage - Reverse Mortgage Icon | Bay Equity Home Loans"
                                />
                            </div>
                            <h1 className={styles.caption}>Reverse Mortgage</h1>
                        </div>
                    </a>
                </div>
            </section>
        </div>
    );
};

export default CurrentHOPanel;
