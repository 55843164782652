import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { mapBlogs } from "../../lib/utility";

import Layout from "../../components/Layout";
import Header from "../../components/Header";
import CopyBlock from "../../components/CopyBlock";
import CurrentHOPanel from "../../components/CurrentHOPanel";
import FindLO from "../../components/FindLO";
import BgImage from "../../components/BgImage";
import BlogRoll from "../../components/BlogRoll";

import refinanceImgLarge from "./../../images/typesImg/Icons_Refinance_large.png";
import homeLoanImgLarge from "./../../images/typesImg/Icons_homeloans_Inactive_large.png";
import secondLoanImgLarge from "./../../images/typesImg/Icons_SecondHome_large.png";
import reverseImgLarge from "./../../images/typesImg/Icons_ReverseMortgage_Large.png";

import bg1 from "./images/break.jpg";
import bg2 from "./images/current-home-owner.jpg";

const CHOPage = ({ data }) => {
    const header = <Header active={["current-homeowner"]} theme="light" />;
    const refi = (
        <span>
            A home refinance loan allows you to renegotiate the terms of your
            current debt obligation, replacing one agreement with another.
            Whether you want to take advantage of a better interest rate or
            consolidate other debt, a home refinance is a great option for
            reducing your monthly payments or freeing up some&nbsp;cash.
        </span>
    );
    const homeLoan = (
        <span>
            Buying a home is a substantial financial investment. And unless you
            have access to large amounts of liquid funds, you’re likely going to
            need a home loan to make your dream come true. So, let us help you
            learn about the many types of home mortgage loans and find the one
            that’s just right&nbsp;for&nbsp;you.
        </span>
    );
    const secondHomeLoan = (
        <span>
            Make no mistake, buying a second home has just as many hurdles as
            your first-time experience. Because nobody is an expert the second
            time around, we’re here to show you how to buy a second home,
            coaching you on everything you need to know about securing that
            second-home&nbsp;mortgage.
        </span>
    );
    const reverseMort = (
        <span>
            For homeowners ages 62 or older, a reverse mortgage loan is a home
            loan that turns your home’s equity into cash. Let us show you how to
            turn your home’s value into a one-time sum, a line of credit or any
            of the other reverse mortgage solutions&nbsp;available.
        </span>
    );
    const bt = "Learn More";

    const { corpBlogs } = data;
    const { edges: corpBlobPosts = [] } = corpBlogs || {};
    const corporateBlogs = mapBlogs(corpBlobPosts);

    return (
        <Layout hasHero={true} header={header}>
            <Helmet>
                <title>
                    Buying A Second Home - Second Home Mortgage | Bay Equity
                    Home Loans
                </title>
                <meta
                    name="description"
                    content="Are you ready to invest in a second home or refinance your existing home loan? Bay Equity’s expert loan officers can support you every step of the way. Contact us today."
                />
                <meta
                    name="keywords"
                    content="buying a second home,second home mortgage,how to buy a second home,second home loan,refinance mortgage,refinance calculator,mortgage refinance calculator,home refinance,refinance home loan,reverse mortgage,what is a reverse mortgage,reverse mortgage solutions"
                />
            </Helmet>
            <CurrentHOPanel />
            <CopyBlock
                title="Refinance mortgage"
                description={refi}
                buttonTitle={bt}
                href="/refinance-mortgage"
                icon={refinanceImgLarge}
                flexD={"row-reverse"}
                name="refinance"
                alt="Renegotiate the Terms of Your Home Loan - Refinance Your Mortage | Bay Equity Home Loans"
            />
            <BgImage bg={bg1} />
            <CopyBlock
                title="Home loan"
                description={homeLoan}
                buttonTitle={bt}
                href="/home-loans"
                icon={homeLoanImgLarge}
                name="homeloan"
                alt="Discover the Different Types of Home Loans - Get a Home Loan | Bay Equity Home Loans"
            />
            <CopyBlock
                title="Second-home loan"
                description={secondHomeLoan}
                buttonTitle={bt}
                href="/home-loans"
                icon={secondLoanImgLarge}
                flexD={"row-reverse"}
                name="secondloan"
                alt="Secure Another Home Loan - Get a Second-Home Loan | Bay Equity Home Loans"
            />
            <BgImage bg={bg2} />
            <CopyBlock
                title="Reverse mortgage"
                description={reverseMort}
                buttonTitle={bt}
                href="/reverse-mortgage"
                icon={reverseImgLarge}
                name="reverse"
                alt="Turn Your Home Equity into Cash - Get a Reverse Mortgage | Bay Equity Home Loans"
            />
            <FindLO />
            {corporateBlogs.length >= 3 && (
                <BlogRoll
                    corporateBlogs={corporateBlogs}
                    blogs={corporateBlogs}
                    showBlogs={true}
                    word="our"
                    url="current-homeowner"
                />
            )}
        </Layout>
    );
};

export default CHOPage;

export const pageQuery = graphql`
    query CurrentHOPageQuery {
        corpBlogs: allBlog(
            limit: 10
            sort: { fields: [date], order: DESC }
            filter: {
                type: { eq: "corporate" }
                categories: { in: ["Current Homeowner"] }
            }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                    date
                    feature
                }
            }
        }
    }
`;
