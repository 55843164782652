import React, { Component } from "react";
import styles from "./styles.module.less";
import background from "./../../../images/currentHero.jpg";

class GetStartedCinemaTop extends Component {
    constructor(props) {
        super(props);
        this.backRef = React.createRef();
    }

    render() {
        let backVideo =
            "https://ucarecdn.com/2d2b2c60-0b11-41e9-8521-ee7ba8f54ded/currentHero.mp4";

        return (
            <section className={styles.GetStartedCinemaTop}>
                <div className={styles.bgVideo}>
                    <video
                        className={styles.back}
                        ref={this.backRef}
                        loop
                        autoPlay
                        muted
                        playsInline>
                        <source src={backVideo} type="video/mp4" />
                        <source src={backVideo} type="video/ogg" />
                        <img
                            src={background}
                            title="Your browser does not support the video tag."
                            alt=""
                        />
                    </video>
                </div>
            </section>
        );
    }
}

export default GetStartedCinemaTop;
